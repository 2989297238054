import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <div id="TITILE">Daugiau nieko nera</div>
          <img src="paper.png" id="rotatable-image" width='400' height='auto'  alt="bonka a"></img>
      </header>
    </div>
  );
}

export default App;

